import React, { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router-dom'
import { SimpleButton } from '../../../components/atoms/SimpleButton'
import { CoachTabelHead } from '../../../components/headers/caochTableHead/CoachTableHead'
import { AddCoachModal } from '../../../components/modals/addCoachModal/AddCoachModal'
import { CoachDetailModal } from '../../../components/modals/coachDetailModal/CoachDetailModal'
import { containsNumbersAndSymbols, continOnlyNumber } from '../../../utils/Utils'
import { addCoach, deleteUser, fetchAllCoach, toggleActiveInactive } from '../../../services/admin/AdminServices'
import { resetErrorSuccessMessage } from '../../../utils/Utils'
import { NoDataFound } from '../../../components/atoms/NoDataFound'
import { BeatOverlayLoader } from '../../../components/loaders/BeatOverlayLoader'
import { Pagination } from '../../../components/modals/pagination/Pagniation'
import addUser from '../../../assets/images/Add-user.png'
import arrow from '../../../assets/images/Icon.svg'
import delteButton from '../../../assets/images/Delete.png'
import { toast } from 'react-toastify';
import { requiredText, somethingWrongText } from '../../../constants/Variables'
import { DeleteModal } from '../../../components/modals/deleteModal/DeleteModal'


export const ManageCoach = () => {
    const [coachList, setCoachList] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [stausModal, setStatusModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [fetDataLoader, setFetchDataLoader] = useState(false);
    const [message, setMessage] = useState({});
    const [triggered, setTriggered] = useState(false);
    const [coachDetailModalStatus, setCoachDetailModalStatus] = useState(false);
    const [coachDetail, setCoachDetail] = useState([]);
    const [coachId, setCoachId] = useState(null);
    const [coachData, setCoachData] = useState(
        {
            name: '',
            designation: '',
            email: '',
            contact: '',
        }
    )
    const [inputError, setInputError] = useState(
        {
            nameError: '',
            designationError: '',
            emailError: '',
            contactError: '',
        }
    )
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModalShow, setDelteModalShow] = useState(false);
    const [delteItemId, setDeleteItemId] = useState('')
    const recordsPerPage = 7;

    const navigate = useNavigate();
    /*****useEffect**************/

    useEffect(() => {
        setFetchDataLoader(true)
        handleFetchData();
    }, [])

    useEffect(() => {
        handleFetchData();
    }, [triggered])


    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = coachList.slice(indexOfFirstRecord,
        indexOfLastRecord);
    const nPages = Math.ceil(coachList.length / recordsPerPage)


    /*****Helper funcionts********/

    const handleFetchData = async (status) => {
        try {
            const data = await fetchAllCoach(status);
            setFetchDataLoader(false);
            if (data.status) {
                setCoachList(data?.data);
            } else {
                toast(data?.message, { type: 'error' });
            }
        } catch (error) {
            setFetchDataLoader(false);
            toast(somethingWrongText, { type: 'error' });
        }
    }

    const hanldeInputValue = (key, e) => {
        setCoachData({ ...coachData, [key]: e })
    }

    const blurHandler = (key, e) => {
        switch (key) {
            case "email":
                if (!coachData.email.trim()) {
                    setInputError({ ...inputError, emailError: "Email field can not be empty!" })
                }
                else {
                    const inputValue = e.trim().toLowerCase();
                    if (!/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(inputValue)) {
                        setInputError({ ...inputError, emailError: "Please enter a valid email id!", })
                    } else {
                        setInputError({ ...inputError, emailError: "", })
                    }
                }
                break;
            // case "contact":
            //     if (!coachData.contact.trim()) {
            //         setInputError({ ...inputError, contactError: 'Contact field can not be empty!' })
            //     }
            //     else {
            //         setInputError({ ...inputError, contactError: "" })
            //     }
            //     break;
            default:
                setInputError({ ...inputError, emailError: '', contactError: '' })
                break;
        }
    };

    const focusHandler = () => {
        setInputError({ nameError: '', designationError: "", emailError: "", passwordError: '', })
    }

    const handleAddCoach = () => {
        //Validation for name
        if (coachData.name.trim() === "") {
            setInputError({ ...inputError, nameError: requiredText })
            return false;
        }
        else {
            let response = containsNumbersAndSymbols(coachData.name.trim())
            if (response) {
                setInputError({ ...inputError, nameError: "Numbers and symbols are not allowed!" })
                return false;
            }
            else {
                setInputError({ ...inputError, nameError: "" })
            }
        }

        //validation for designation
        if (coachData.designation.trim() === "") {
            setInputError({ ...inputError, designationError: requiredText })
            return false;
        }
        else {
            let response = containsNumbersAndSymbols(coachData.designation.trim())
            if (response) {
                setInputError({ ...inputError, designationError: 'Numbers and symbols are not allowed!' })
                return false;
            }
            else setInputError({ ...inputError, designationError: "" })
        }

        //validation for email
        if (coachData.email.trim() === "") {
            setInputError({ ...inputError, emailError: requiredText })
            return false;
        }
        else setInputError({ ...inputError, emailError: "" })

        //validation for contact
        if (coachData.contact.trim() === "") {
            setInputError({ ...inputError, contactError: requiredText })
            return false;
        }
        else {
            let response = continOnlyNumber(coachData.contact.trim())
            // alert(response)
            if (!response && coachData.contact.trim().length > 0) {
                setInputError({ ...inputError, contactError: 'Only digits are allowed!' })
                return false;
            } else if (coachData.contact.trim().length !== 10) {
                setInputError({ ...inputError, contactError: 'Contact number must be 10 digits long!' })
                return false;
            }
            else {
                setInputError({ ...inputError, contactError: '' })
            }
        }
        const requestObject = {
            name: coachData.name,
            email: coachData.email,
            phoneNumber: coachData.contact,
            designation: coachData.designation,
        }
        setLoader(true);
        const response = addCoach(requestObject);
        response.then((data) => {
            setLoader(false)
            setMessage(data);
            resetErrorSuccessMessage(setMessage)
            if (data.status === true) {
                setCoachData({
                    name: '',
                    designation: '',
                    email: '',
                    contact: '',
                })
                setModalShow(false);
                setTriggered(!triggered)
                // handleFetchData();
            }
        })
            .catch((error) => {
                setLoader(false)
                setMessage({ status: false, message: 'Someting went wrong, please try again later!' })
                resetErrorSuccessMessage(setMessage)
                // console.log(error, "its error while adding new coach")
            })
    }

    const handleActiveInactive = (id, status) => {
        const requestObject = {
            Id: id,
            status: status === "Active" ? "Inactive" : "Active",
            role: '222'
        }
        const response = toggleActiveInactive(requestObject)   // api call to to active inactive coach
        response.then((data) => {
            if (data.status) {
                setTriggered(!triggered)  //if success then re-call the api to fetch all caoches
                toast(data?.message, { type: 'success' });
            }
            else {
                toast(data?.message, { type: 'error' });   //othterwise popup a error message
            }
        }).catch((error) => {
            toast(somethingWrongText, { type: 'error' });
        })
    }

    const handleDelete = (id) => {
        const requestObject = {
            userId: id,
            role: "222",
        }
        setDelteModalShow(false);  //close delete modal
        const response = deleteUser(requestObject)  //delete api call 
        response.then((data) => {
            if (data.status) {
                setTriggered(!triggered) //if success then re-call the api to fetch all caoches
                toast(data?.message, { type: 'success' });
            }
            else {
                toast(data?.message, { type: 'error' });   //othterwise popup a error message
            }
        })
            .catch((error) => {
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const handleClientListButton = (coachId) => {
        navigate(`/app/manage-clients`, {
            state: {
                coachId,
            }
        })
    }

    const handleDeleteModal = (id) => {
        setDeleteItemId(id)
        setDelteModalShow(true)
    }

    const handleUpdateCoachList = () => {
        handleFetchData(); // Re-fetch the updated list of coaches
        setCoachDetailModalStatus(false); // Close the modal
    };

    const coachModalHandler = (item) => {
        setCoachDetailModalStatus(!coachDetailModalStatus);
        setCoachDetail(item); // item should be a single coach object
        setCoachId(item.id);
    }


    return (
        <>
            <CoachTabelHead
                total={(coachList?.length !== null && coachList?.length !== 0) ? coachList?.length : "0"}
                userName="Coaches"
                setModalShow={setModalShow}
                modalShow={modalShow}
                logo={addUser}
                text="Add New Coach"
            />
            <div className={`table-wrapper-container`} >
                <div className='table-container'>
                    <div className="divTable">
                        <div className="headRow">
                            <div className="divCell">Name</div>
                            <div className="divCell">Designation</div>
                            <div className="divCell">Email</div>
                            <div className="divCell">Phone number</div>
                            <div className="divCell" onClick={() => setStatusModal(!stausModal)}>
                                Status
                                {stausModal ?
                                    <>
                                        <img src={arrow} alt="left-arrow " className=' left-arrow upWard' />
                                        {/* <RxCaretUp style={{ color: '#7525E8', width: '15px', height: '15px' }} /> */}
                                        <div className="profile-toast status-toast">
                                            <p onClick={() => handleFetchData()}>
                                                All
                                            </p>
                                            <p onClick={() => handleFetchData("Active")}>
                                                Active
                                            </p>
                                            <p onClick={() => handleFetchData("Inactive")}>
                                                Inactive
                                            </p>
                                        </div>
                                    </> :
                                    <img src={arrow} alt="left-arraow" className=' left-arrow  downWard' />
                                }
                            </div>
                            <div className="divCell">Clients</div>
                            <div className="divCell"></div>
                        </div>
                        {
                            currentRecords.length !== undefined && currentRecords.length !== 0 && currentRecords.length !== null ?
                                currentRecords.map((item) => {
                                    return (
                                        <div className="divRow" key={item?.id}>
                                            <div className="divCell" onClick={() => {
                                                coachModalHandler(item);
                                            }}>
                                                {item?.name}
                                            </div>
                                            <div className="divCell" onClick={() => {
                                                coachModalHandler(item);
                                            }}>{item?.designation}</div>
                                            <div className="divCell" onClick={() => {
                                                coachModalHandler(item);
                                            }}>{item?.email}</div>
                                            <div className="divCell" onClick={() => {
                                                coachModalHandler(item);
                                            }}>{item.phoneNumber}</div>
                                            <div className="divCell"><SimpleButton buttonText={item.status} buttonColor={item.status === 'Active' ? "buttonAcive" : "buttonInactive"} width="50%" handleButton={() => handleActiveInactive(item?.id, item?.status)} textColor="#F4FFF3" /></div>
                                            <div className="divCell"><SimpleButton buttonText="client list" buttonColor="outlineButton" width="100%" handleButton={() => handleClientListButton(item?.id)} textColor="#F4FFF3" /></div>
                                            {/* <div className="divCell"><SimpleButton buttonText="Delete" buttonColor="deleteButton" width="100%" handleButton={() => handleDelete(item?.id)} textColor="#F4FFF3" /></div> */}
                                            <div className="divCell" onClick={() => handleDeleteModal(item?.id)}>
                                                <img className="delete" src={delteButton} alt="delete button">
                                                </img>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <NoDataFound />
                        }
                    </div>
                </div>
            </div>
            <div className='table-pagination-container'>
                <Pagination nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage} />
            </div>
            <AddCoachModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                coachData={coachData}
                setCoachData={setCoachData}
                focusHandler={focusHandler}
                blurHandler={blurHandler}
                hanldeInputValue={hanldeInputValue}
                inputError={inputError}
                handleaddcoach={handleAddCoach}
                loader={loader}
                message={message}
            />
            <DeleteModal
                show={deleteModalShow}
                onHide={() => setDelteModalShow(false)}
                handleDelete={() => handleDelete(delteItemId)}
                title="Delete Coach"
                message="Are you sure, do you want to delete this coach?"
            />
            {fetDataLoader &&
                <BeatOverlayLoader />
            }
            {
                <CoachDetailModal
                    show={coachDetailModalStatus}
                    onHide={() => setCoachDetailModalStatus(false)}
                    coachDetail={coachDetail}
                    coachId={coachId}
                    onUpdate={handleUpdateCoachList}
                />
            }
        </>
    )
}
