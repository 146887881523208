import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../BaseModal.css';
import { updateCoachProfile } from "../../../services/common/CommonServices";
import { toast } from 'react-toastify';

export const CoachDetailModal = ({ show, onHide, coachDetail, coachId, onUpdate }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [editedFields, setEditedFields] = useState({});
    const [initialValues, setInitialValues] = useState({});

    // Initialize the editedFields when coachDetail changes 
    useEffect(() => {
        if (coachDetail) {
            console.log("Coach Detail:", coachDetail);
            setInitialValues({
                name: coachDetail.name,
                email: coachDetail.email,
                phoneNumber: coachDetail.phoneNumber,
                designation: coachDetail.designation,
                status: coachDetail.status,
            });
        }
    }, [coachDetail]);
    

    // Reset the state when the modal is closed
    useEffect(() => {
        if (!show) {
            // Reset the states to initial values when modal is closed
            setIsEditable(false);
            setEditedFields({});
            setInitialValues({});
        }
    }, [show]);

    const handleEditClick = async () => {
        if (isEditable) {
            const updatedFields = Object.keys(editedFields).reduce((acc, key) => {
                if (editedFields[key] !== initialValues[key]) {
                    acc[key] = editedFields[key];
                }
                return acc;
            }, {});
    
            if (Object.keys(updatedFields).length > 0) {
                try {
                    const payload = { ...updatedFields, coachId };
                    console.log('Payload for update:', payload);
    
                    const response = await updateCoachProfile(payload);
                    if (response?.status === true) {
                        // setTimeout(() => {
                        //     window.location.href='/app/manage-coach';
                        // }, 3500);
                        onUpdate();
                        toast(response?.message, { type: 'success' });
                    } else {
                        toast(response?.message, { type: 'error' });
                    }
                } catch (error) {
                    console.error('Error updating profile:', error);
                    toast("An error occurred while updating the profile.", { type: 'error' });
                }
            } else {
                toast("No fields were updated.", { type: 'error' });
            }
        }
        setIsEditable((prevState) => !prevState);
    };
    
    
    

    const handleFieldChange = (field, value) => {
        if (value !== initialValues[field]) {
            setEditedFields((prevState) => ({
                ...prevState,
                [field]: value,
            }));
        } else {
            setEditedFields((prevState) => {
                const newState = { ...prevState };
                delete newState[field]; // If the value is the same as the initial value, remove the field from the updated object
                return newState;
            });
        }
    };
    

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="modal-header">
                <Modal.Title id="contained-modal-title-vcenter">
                    Coach Information
                </Modal.Title>
                <button
                    className="btn btn-secondary me-2"
                    onClick={handleEditClick}
                    style={{
                        fontSize: '16px',
                        width: '20%',
                        borderRadius: '50px',
                        backgroundColor: '#F4F7FE',
                        color: 'black',
                        border: 'none',
                        fontWeight: '450',
                    }}
                >
                    {isEditable ? 'Save Changes' : 'Edit Profile'}
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="common-card client-info-box">
                    <div className="client-detail-wrapper">
                        {/* Coach ID */}
                        <div className="item-wrapper">
                            <p className="key">Coach ID</p>
                            <div className="overlap-3" style={{
                                background: isEditable && "rgb(235 235 228 / 40%)",
                                pointerEvents: isEditable && 'none',
                            }}>
                                <p className="span-wrapper">{coachId}</p>  
                            </div>
                        </div>
                        {/* Editable Email ID */}
                        <div className="item-wrapper">
                            <p className="key">Email ID</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('email', e.target.innerText)}
                                >
                                    {editedFields.email || coachDetail?.email}
                                </p>
                            </div>
                        </div>
                        {/* Editable Phone Number */}
                        <div className="item-wrapper">
                            <p className="key">Phone Number</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('phoneNumber', e.target.innerText)}
                                >
                                    {editedFields.phoneNumber || coachDetail?.phoneNumber}
                                </p>
                            </div>
                        </div>
                        {/* Editable Full Name */}
                        <div className="item-wrapper">
                            <p className="key">Full Name</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('name', e.target.innerText)}
                                >
                                    {editedFields.name || coachDetail?.name}
                                </p>
                            </div>
                        </div>
                        {/* Editable Designation */}
                        <div className="item-wrapper">
                            <p className="key">Designation</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('designation', e.target.innerText)}
                                >
                                    {editedFields.designation || coachDetail?.designation}
                                </p>
                            </div>
                        </div>
                        {/* Editable Status */}
                        <div className="item-wrapper">
                            <p className="key">Status</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('status', e.target.innerText)}
                                >
                                    {editedFields.status || coachDetail?.status}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer"></Modal.Footer>
        </Modal>
    );
};
